<template>
  <div class="privacy">
    <!-- 头部 -->
    <logged-heard></logged-heard>
    <!-- 内容区域 -->
    <div class="head" v-if="detailObj">
      <div class="top">
        <div class="center">
          <div class="company-name">
            <img :src="detailObj.logo_url" alt="" />
            <div class="right-name">
              <span>{{ detailObj.ent_name }}</span>
              <span
                >{{ detailObj.province ? detailObj.province : "无" }}·{{
                  detailObj.other_info.scale.text
                }}·{{ detailObj.other_info.industry.text }}</span
              >
            </div>
          </div>
          <div class="right-message">
            <span>{{total}}</span>
            <span>在招职位</span>
            <span class="pointer" @click="navTo('/my-resume')" v-if="hasLogin && userDetail.detail.completeness<90 ">完善在线简历</span>
          </div>
        </div>
      </div>
      <div class="tab">
        <div
          class="tab-list pointer"
          v-for="(item, index) in tabsParam"
          :key="index"
          @click="toggleTabs(index)"
          :class="{ active: index == nowIndex }"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div class="message" v-if="detailObj">
      <div class="divTab" v-show="nowIndex === 0">
        <!-- 内容区域 -->
        <div class="content-area">
          <div class="left">
            <div class="title">{{ detailObj.ent_name }}简介</div>
            <div class="texter">
              {{ detailObj.introduction }}
            </div>
            <div class="Corporate-welfare">
              <span>企业福利</span>
              <div class="box-right">
                <div class="box" v-for="(item, index) in detailObj.welfare" :key="index">
                  <span>{{ item }}</span>
                </div>
              </div>
            </div>
            <div class="Corporate-welfare">
              <span>企业风采</span>
              <div class="job-img">
                <img
                  :src="item.file_path"
                  alt=""
                  v-for="item in detailObj.styles"
                  :key="item.file_id"
                />
              </div>
              <div class="sit">
                <div>工作地址</div>
                <div>
                  {{ detailObj.province }}{{ detailObj.city }}{{ detailObj.address }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="divTab" v-show="nowIndex === 1">
        <div
          class="recruiting pointer"
          v-for="item in posiList"
          :key="item.id"
          @click="navTo('/position-details', item.id)"
        >
          <div class="top">{{ item.position_name }}【{{ item.province }}】</div>
          <div class="center">
            <img :src="item.ent.logo_url" alt="" />
            <span>{{ item.hr.user_name }} · HR</span>
          </div>
          <div class="bottom">
            <span>{{ item.salary_min }}-{{ item.salary_max }}</span>
            {{ item.experience }} {{ item.education }}
          </div>
        </div>
        <div class="Job-not" v-if="posiList.length == 0">暂无发布职位</div>
        <!-- 分页 -->
        <div class="pading">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :hide-on-single-page="valuePageShow"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
      <div class="right-login">
        <div class="el-login">
          <register :showCompany="false" :showMessage="false"></register>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <feet-model></feet-model>
  </div>
</template>

<script>
import loggedHeard from "@/components/loggedHeard.vue";
import feetModel from "@/components/feet.vue";
import tabbarModel from "@/components/tabbar.vue";
import register from "../../../components/register.vue";
import api from "../../../api/enterprises";
import posiApi from "../../../api/position";
import { mapGetters, mapState } from "vuex";
export default {
  components: { loggedHeard, feetModel, tabbarModel, register },
 computed: {
    ...mapGetters(["hasLogin"]),
    ...mapState(["userDetail"]),
  },
  data() {
    return {
      tabsParam: ["公司简介", "在招职位（3）"],
      nowIndex: 0,
      detailObj: null,
      query: {
        pageSize: 10,
        page: 1,
        enterprise_id: "",
      },
      posiList: [],
      total:0,
      valuePageShow: true,
    };
  },

  created() {
    if (this.$route.query.id) {
      this.query.enterprise_id = this.$route.query.id;
      this.entDetail(this.$route.query.id);
      this.posiClick();
    }
  },

  mounted() {},

  methods: {
     // 页数跳转
    currentChange(val){
      this.query.page=val
      this.posiClick();
    },
    // 获取企业相关的职位
    posiClick() {
      posiApi.posiList(this.query).then((res) => {
        if (res.code == 200) {
          this.tabsParam = ["公司简介", `在招职位（${res.data.total}）`];
          this.posiList = res.data.data;
          this.total=res.data.total
          if (res.data.last_page > 1) {
            this.valuePageShow = true;
          }
        }
      });
    },
    entDetail(id) {
       const loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      api.releasePosition({ id }).then((res) => {
        if (res.code == 200) {
          this.detailObj = res.data;
        }
        loading.close()
      });
    },
    toggleTabs(index) {
      this.nowIndex = index;
    },
    navTo(path, id) {
      this.$router.push({ path, query: { id } });
    },
  },
};
</script>
<style lang="less" scoped>
.privacy {
  width: 100%;
  background: #f2f2f5;
  min-height: 100vh;
  .head {
    width: 100%;
    height: 11.5625rem;
    background: #454555;
    .top {
      display: flex;
      flex-direction: column;
    }
    .center {
      width: 80%;
      height: 100%;
      margin: auto;
      .company-name {
        width: 100%;
        padding: 1.25rem 0;
        img {
          width: 6.4375rem;
          height: 6.4375rem;

          border-radius: 13px;

          float: left;
        }
        .right-name {
          float: left;
          display: flex;
          flex-direction: column;
          font-size: 2rem;
          font-weight: 400;

          color: #ffffff;
          line-height: 2.5rem;
          padding: 0.8rem 1.25rem;
          span:nth-child(2) {
            font-size: 1rem;
            font-weight: 400;

            color: #b5b8c1;
          }
        }
      }
      .right-message {
        float: right;
        margin-top: -1.25rem;
        padding: 0 11rem;
        line-height: 40px;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        span:nth-child(1) {
          font-size: 2.25rem;
        }
        span:nth-child(2) {
          font-size: 0.875rem;
        }
        span:nth-child(3) {
          font-size: 1.125rem;
          color: #fa6a43;
        }
      }
    }
    .tab {
      width: 86.2%;
      height: 6.25rem;
      margin: auto;
      display: flex;
      padding-top: 0.8rem;
      .tab-list {
        height: 1.975rem;
        // background: red;
        color: #fff;
        margin-left: 3.1875rem;
        .active {
          color: red;
        }
      }
    }
  }
}
.active {
  border-bottom: 3px solid #126bf9;
}
.message {
  width: 81%;
  margin: 0 auto;
  display: flex;
  .divTab {
    width: 78%;
    padding-top: 3rem;
    .content-area {
      margin: auto;
      display: flex;
      .left {
        border-right: 1px solid #f4f4f6;
        .texter {
          width: 70%;
          padding-top: 1.875rem;
          font-size: 0.875rem;
          font-weight: 400;
          color: #61687c;
          line-height: 1.5625rem;
        }
      }
      .Corporate-welfare {
        display: flex;
        flex-direction: column;
        padding-top: 2.4375rem;

        .box-right {
          float: right;
          padding-top: 2.5rem;
          .box {
            display: inline-block;
            margin-right: 1.25rem;
            text-align: center;
            line-height: 2rem;
            border-radius: 3px;
            font-size: 0.875rem;
            color: #126bf9;
            height: 2rem;
            border: 1px solid #126bf9;
            border-radius: 0.1875rem;
            padding: 0 8px;
          }
        }
        .job-img {
          margin-left: -1.25rem;
          padding-top: 1.875rem;
          img {
            width: 18.8125rem;
            height: 12.625rem;
            margin-left: 1.25rem;
          }
        }
        .sit {
          padding-top: 1.875rem;
          line-height: 1.875rem;
          font-size: 1rem;
          font-weight: 400;
          color: #61687c;
        }
      }
    }
    .recruiting {
      width: 74.5rem;
      height: 8.75rem;
      background: #fff;
      margin-bottom: 0.625rem;
      .top {
        font-size: 1rem;
        font-weight: 400;
        color: #126bf9;
        padding: 41px 1.8125rem 0;
      }
      .center {
        text-align: right;
        font-size: 0.875rem;
        font-weight: 400;
        color: #4a4160;
        padding: 0 1.875rem;

        img {
          width: 1.25rem;
          height: 1.25rem;
          border-radius: 50%;
          vertical-align: middle;
          display: inline-block;
          margin: 0 0.875rem 0.12rem;
        }
      }
      .bottom {
        font-size: 1rem;
        font-weight: 400;
        padding: 0 1.8125rem;
        padding-top: 0.625rem;
        color: #61687c;
        span {
          color: #fc7031;
        }
      }
     
    }
  }
  .right-login {
    width: 19.8125rem;
    margin-left: 1.25rem;
    padding-top: 3.125rem;
    align-items: flex-start;
    margin-bottom: 1.25rem;

    .el-login {
      width: 317px;
      height: 347px;
      // background: #126bf9;
      margin-bottom: 20px;
    }
    .have-seen {
      width: 19.8125rem;
      height: 19.5rem;
      background: #ffffff;
    }
  }
}
 .Job-not{
        text-align: center;
        margin-top: 100px;
      }
      .pading {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        ::v-deep.btn-prev {
          background: #fff;
        }
        ::v-deep .el-pagination.is-background .btn-next,
        .el-pagination.is-background .btn-prev,
        .el-pagination.is-background .el-pager li {
          background: #fff;
        }
        ::v-deep .el-pagination.is-background .el-pager li {
          background: #fff;
          color: #414a60;
        }
        ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
          background: #126bf9;
          color: #fff;
        }
      }
</style>
